import "./map-calc.scss"

const {notie, $, GEOCODER: {getMKADDistance}} = window

const $map = document.getElementById("map-calc-container")
const $markers = [...document.querySelectorAll("#map-calc-container .marker")]

if ($map) {

  const center = JSON.parse($map.dataset.center)
  const zoom = parseInt($map.dataset.zoom)

  const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)

  window.addEventListener("ymaps_ready", function (e) {

    const {GEOCODER: {mapObject}} = window

    const map = new mapObject.Map('map-calc-container', {
      center,
      zoom,
      controls: ['zoomControl']
    })
    if (!isMobile) map.behaviors.disable('scrollZoom')
    $markers.forEach($marker => {
      const marker = new mapObject.Placemark(
        JSON.parse($marker.dataset.center),
        {
          hintContent: $marker.dataset.title,
          balloonContentHeader: $marker.dataset.title,
          balloonContentBody: $marker.dataset.body,
          balloonContentFooter: $marker.dataset.footer,
        }, {
          iconLayout: 'default#image',
          hideIconOnBalloonOpen: true,
          iconImageHref: 'assets/images/logo-mobile@3x.png',
          iconImageSize: [46, 94],
          iconImageOffset: [-23, -94]
        })
      map.geoObjects.add(marker)
    })
  })

}

$(document).ready(() => {

  $('[name="pickup[]"]').styler()

  const $slider = $("#map-calc-volume-slider")
  const $value = $("#map-calc-volume-value")
  const $sum = $("#map-calc-sum")
  const $delivery = $("#map-calc-delivery")
  const $address = $("#map-calc-address")
  const $phone = $("#map-calc-phone")
  const $volume = $("#map-calc-volume")
  $address.changed = false

  const $form = $("#map-calc-form")

  const $btn = $("#map-calc-btn")
  $btn.type = "send"

  $btn.calc = () => {
    $btn.find("span").text("Рассчитать")
    $btn.type = "calc"
  }

  $btn.send = () => {
    $btn.find("span").text("Отправить заявку")
    $btn.type = "send"
  }


  $form.on("submit", function (event) {

    event.preventDefault()

    if (!$address.val() && $delivery.prop("checked")) {
      $address.addClass("is-invalid")
      return false
    }

    if ($btn.type === "calc") {

      if ($address.changed) {
        getMKADDistance($address.val(), distance => {
          if (distance < 0) {
            $address.addClass("is-invalid")
            return
          }
          $address.removeClass("is-invalid")
          $sum.html(calculate(null, distance))
          $btn.send()
          $address.changed = false
          console.log(distance)
        })
      }

    } else {
      if (!$phone.val()) {
        $phone.addClass("is-invalid")
        return false
      }

      const data = $form.serialize()
      $.ajax({
        type: "POST",
        url: "/local/ajax/calc_send.php",
        data: data
      }).done(function (msg) {
        if (msg === "success") {
          $form.trigger("reset")
          notie.alert({
            type: 'success',
            text: "Форма успешно отправлена!",
          })
        } else {
          notie.alert({
            type: 'error',
            text: "Что-то пошло не так! Попробуйте ещё раз позднее.",
          })
        }
      })
    }

    return false
  })

  const price = $slider.data("price")

  $("#map-calc-form :input").change(function () {

    if ($delivery.prop("checked")) {
      if (!$address.val()) {
        $address.val($address.data("value"))
      }
      $address.prop("disabled", false).focus()
      $btn.calc()
    } else {
      if ($address.val()) {
        $address.data("value", $address.val())
        $address.val("")
      }
      $address.prop("disabled", true).blur()
      $btn.send()
    }
  })

  function calculate(value, distance = 0) {

    distance = distance / 1000

    if (distance < 90) {
      distance = 0
    } else {
      distance = Math.ceil(distance / 10)
    }

    if (!value) value = $slider.slider("value")
    return `<b>${new Intl.NumberFormat('ru-RU').format((price * value) + (distance * 1000))}</b><sup> &#8381;</sup>`
  }

  $address.on("change", function () {
    $btn.calc()
    $address.removeClass("is-invalid")
    $address.changed = true
  })

  $phone.on("change", function () {
    $phone.removeClass("is-invalid")
  })

  $slider.slider({
    range: "max",
    min: $slider.data("min") || 1,
    max: $slider.data("max") || 50,
    value: $volume.val(),
    slide: function (event, ui) {
      $value.text(ui.value)
      $volume.val(ui.value)
      if (!$delivery.prop("checked")) {
        $sum.html(calculate(ui.value))
      } else {
        $btn.calc()
        $address.changed = true
      }
    }
  })
  $slider.append(`<div class="min">${$slider.data("min")}</div>`)
  $slider.append(`<div class="max">${$slider.data("max")}</div>`)
  $value.text($slider.slider("value"))
  $sum.html(calculate())
})