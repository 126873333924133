import "./calc.scss"

const {notie, $} = window

$(document).ready(() => {

  $('[name="full[]"]').styler()

  const $volumeSlider = $("#calc-volume-slider")
  const $volumeValue = $("#calc-volume-value")
  const $volume = $("#calc-volume")

  const $restSlider = $("#calc-rest-slider")
  const $restValue = $("#calc-rest-value")
  const $rest = $("#calc-rest")

  const $fullCheckbox = $("#calc-full-checkbox")
  const $partValue = $("#calc-part-value")


  const $arrow = $("#calc-arrow")


  const $address = $("#calc-address")
  const $phone = $("#calc-phone")
  const $name = $("#calc-name")

  $address.changed = false

  const $form = $("#calc-form")

  const $btn = $("#calc-btn")
  // $btn.type = "calc"

  $btn.calc = () => {
    $btn.find("span").text("Рассчитать")
    $btn.type = "calc"
  }

  $btn.send = () => {
    $btn.find("span").text("Отправить заявку")
    $btn.type = "send"
  }

  $btn.send()


  $form.on("submit", function (event) {
    event.preventDefault()
    let isValid = true

    if (!$name.val()) {
      $name.addClass("is-invalid")
      isValid = false
    } else {
      $name.removeClass("is-invalid")
    }

    if (!$phone.val()) {
      $phone.addClass("is-invalid")
      isValid = false
    } else {
      $phone.removeClass("is-invalid")
    }

    if (!$address.val()) {
      $address.addClass("is-invalid")
      isValid = false
    } else {
      $address.removeClass("is-invalid")
    }

    calculate()


    if (!isValid) return false
    const data = $form.serialize()
    $.ajax({
      type: "POST",
      url: "/local/ajax/calc_send.php",
      data: data
    }).done(function (msg) {
      if (msg === "success") {
        $form.trigger("reset")
        $btn.calc()
        notie.alert({
          type: 'success',
          text: "Форма успешно отправлена!",
        })
      } else {
        notie.alert({
          type: 'error',
          text: "Что-то пошло не так! Попробуйте ещё раз позднее.",
        })
      }
    })

    return false
  })


  $("#calc-form :input").change(function () {
    if ($fullCheckbox.prop("checked")) {
      $partValue.prop("disabled", true).blur()

      $volumeSlider.slider("enable")
      $restSlider.slider("enable")
    } else {

      $partValue.prop("disabled", false).focus()
      $volumeSlider.slider("disable")
      $restSlider.slider("disable")

    }
  })

  function calculate(value, rest, distance = 0) {

    distance = distance / 1000

    if (distance < 90) {
      distance = 0
    } else {
      distance = Math.ceil(distance / 10)
    }

    if (!value) value = $volumeSlider.slider("value")
    if (!rest) rest = $restSlider.slider("value")

    let val

    if ($fullCheckbox.prop("checked")) {
      val = value * (.85 - Math.min(rest / 100, .85))
    } else {
      val = $partValue.val()
    }

    $("#calc-total-volume").text(new Intl.NumberFormat('ru-RU').format(Math.ceil(val)))
    $("#calc-total-price").text(new Intl.NumberFormat('ru-RU').format(Math.ceil(val * $("#calc-price").data("price") + (distance * 1000))))
  }

  $address.on("change", function () {
    // $btn.calc()
    $address.removeClass("is-invalid")
    $address.changed = true
  })


  $phone.on("change", function () {
    $phone.removeClass("is-invalid")
  })

  $name.on("change", function () {
    $name.removeClass("is-invalid")
  })

  $partValue.on("change", function () {
    calculate()
  })

  $volumeSlider.slider({
    range: "max",
    min: $volumeSlider.data("min") || 100,
    max: $volumeSlider.data("max") || 50000,
    step: 100,
    value: $volume.val(),
    slide: function (event, ui) {
      $volumeValue.text(ui.value)
      $volume.val(ui.value)
      calculate()
    }
  })
  $volumeValue.text($volumeSlider.slider("value"))

  const min = $restSlider.data("min") || 1
  const max = $restSlider.data("max") || 85

  $restSlider.slider({
    range: "max",
    min: min,
    max: max,
    step: 1,
    // value: 0,
    value: $rest.val(),
    slide: function (event, ui) {
      $restValue.text(ui.value)
      $rest.val(ui.value)
      $arrow.css("transform", `rotate(${(ui.value / max * 280) - 265}deg)`)
      calculate()
    }
  })
  $restValue.text($restSlider.slider("value"))
  $arrow.css("transform", `rotate(${($restSlider.slider("value") / max * 280) - 265}deg)`)

  calculate()
})

