import "./map.scss"



const $map = document.getElementById("map-container")

if ($map) {

  const $markers = [...document.querySelectorAll("#map-container .marker")]

  const center = JSON.parse($map.dataset.center)
  const zoom = parseInt($map.dataset.zoom)

  const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)

  window.addEventListener("ymaps_ready", function (e) {

    const {GEOCODER: {mapObject}} = window

    const map = new mapObject.Map('map-container', {
      center,
      zoom,
      controls: ['zoomControl']
    })
    if (!isMobile) map.behaviors.disable('scrollZoom')
    $markers.forEach($marker => {
      const marker = new mapObject.Placemark(
        JSON.parse($marker.dataset.center),
        {
          hintContent: $marker.dataset.title,
          balloonContentHeader: $marker.dataset.title,
          balloonContentBody: $marker.dataset.body,
          balloonContentFooter: $marker.dataset.footer,
        }, {
          iconLayout: 'default#image',
          hideIconOnBalloonOpen: true,
          iconImageHref: 'assets/images/logo-mobile@3x.png',
          iconImageSize: [46, 94],
          iconImageOffset: [-23, -94]
        })
      map.geoObjects.add(marker)
    })
  })
}