import ymaps from 'ymaps'

function inPoly(x, y, mkad_points, i) {
  let j = mkad_points.length - 1
  let c = false // true/false - внутри или вне полигона
  for (i = 0; i < mkad_points.length; i++) {
    if ((((mkad_points[i][1] <= y) && (y < mkad_points[j][1])) || ((mkad_points[j][1] <= y) && (y < mkad_points[i][1]))) && (x > (mkad_points[j][0] - mkad_points[i][0]) * (y - mkad_points[i][1]) / (mkad_points[j][1] - mkad_points[i][1]) + mkad_points[i][0])) {
      c = !c
    }
    j = i
  }
  return c
}

const mkad_points = [
  [37.842663, 55.774543],
  [37.842663, 55.774543],
  [37.84269, 55.765129],
  [37.84216, 55.75589],
  [37.842232, 55.747672],
  [37.841109, 55.739098],
  [37.840112, 55.730517],
  [37.839555, 55.721782],
  [37.836968, 55.712173],
  [37.832449, 55.702566],
  [37.829557, 55.694271],
  [37.831425, 55.685214],
  [37.834695, 55.676732],
  [37.837543, 55.66763],
  [37.839295, 55.658535],
  [37.834713, 55.650881],
  [37.824948, 55.643749],
  [37.813746, 55.636433],
  [37.803083, 55.629521],
  [37.793022, 55.623666],
  [37.781614, 55.617657],
  [37.769945, 55.61114],
  [37.758428, 55.604819],
  [37.747199, 55.599077],
  [37.736949, 55.594763],
  [37.721013, 55.588135],
  [37.709416, 55.58407],
  [37.695708, 55.578971],
  [37.682709, 55.574157],
  [37.668471, 55.57209],
  [37.649948, 55.572767],
  [37.63252, 55.573749],
  [37.619243, 55.574579],
  [37.600828, 55.575648],
  [37.586814, 55.577785],
  [37.571866, 55.581383],
  [37.55761, 55.584782],
  [37.534541, 55.590027],
  [37.527732, 55.59166],
  [37.512227, 55.596173],
  [37.501959, 55.602902],
  [37.493874, 55.609685],
  [37.485682, 55.616259],
  [37.477812, 55.623066],
  [37.466709, 55.63252],
  [37.459074, 55.639568],
  [37.450135, 55.646802],
  [37.441691, 55.65434],
  [37.433292, 55.66177],
  [37.425513, 55.671509],
  [37.418497, 55.680179],
  [37.414338, 55.687995],
  [37.408076, 55.695418],
  [37.397934, 55.70247],
  [37.388978, 55.709784],
  [37.38322, 55.718354],
  [37.379681, 55.725427],
  [37.37483, 55.734978],
  [37.370131, 55.745291],
  [37.369368, 55.754978],
  [37.369062, 55.763022],
  [37.369691, 55.771408],
  [37.370086, 55.782309],
  [37.372979, 55.789537],
  [37.37862, 55.796031],
  [37.387047, 55.806252],
  [37.390523, 55.81471],
  [37.393371, 55.824147],
  [37.395176, 55.832257],
  [37.394476, 55.840831],
  [37.392949, 55.850767],
  [37.397368, 55.858756],
  [37.404564, 55.866238],
  [37.417446, 55.872996],
  [37.429672, 55.876839],
  [37.443129, 55.88101],
  [37.45955, 55.882904],
  [37.474237, 55.88513],
  [37.489634, 55.889361],
  [37.503001, 55.894737],
  [37.519072, 55.901823],
  [37.529367, 55.905654],
  [37.543749, 55.907682],
  [37.559757, 55.909418],
  [37.575423, 55.910881],
  [37.590488, 55.90913],
  [37.607035, 55.904902],
  [37.621911, 55.901152],
  [37.633014, 55.898735],
  [37.652993, 55.896458],
  [37.664905, 55.895661],
  [37.681443, 55.895106],
  [37.697513, 55.894046],
  [37.711276, 55.889997],
  [37.723681, 55.883636],
  [37.736168, 55.877359],
  [37.74437, 55.872743],
  [37.75718, 55.866137],
  [37.773646, 55.8577],
  [37.780284, 55.854234],
  [37.792322, 55.848038],
  [37.807961, 55.840007],
  [37.816127, 55.835816],
  [37.829665, 55.828718],
  [37.836914, 55.821325],
  [37.83942, 55.811538],
  [37.840166, 55.802472],
  [37.841145, 55.793925],
]

window.GEOCODER = {
  mapObject: null,
  getMKADDistance(address, cb) {
    const {mapObject} = window.GEOCODER

    if (!mapObject) {
      console.error("Проблема с API Яндекс Карт")
      return
    }

    let minShir = mkad_points[0][0]
    let minDolg = mkad_points[0][1]
    let minDelta = 10000
    let minJ = 0
    let delta = 100000
    let geocoder = null
    let router

    let i = 0 //mkad distance 0 - в мкаде, -1 - адрес определяется, -2 ошибка определения адреса, -3 - яндекс не работал

    // начинаем геокодирование (определение координат по адресу)
    geocoder = new mapObject.geocode(address)
      .then(function (res) {

        const firstGeoObject = res.geoObjects.get(0)
        // Координаты геообъекта.
        const coords = firstGeoObject.geometry.getCoordinates()
        const Shir = coords[1]
        const Dolg = coords[0]

        console.log("geocoder OK Shir=" + Shir + " Dolg=" + Dolg)

        if (!inPoly(Shir, Dolg, mkad_points, i)) {

          // точка за МКАДом, считаем маршрут
          console.log("Точка за пределами МКАД")

          for (let j = 0; j < mkad_points.length; j++) {
            // проверяем, какая из точек ближе всего
            delta = (mkad_points[j][0] - Shir) * (mkad_points[j][0] - Shir) + (mkad_points[j][1] - Dolg) * (mkad_points[j][1] - Dolg)
            // console.log(delta + ' ' + minDelta);
            if (delta < minDelta) {
              minShir = mkad_points[j][0] // широта ближайшей точки
              minDolg = mkad_points[j][1] // долгота ближайшей точки
              minJ = j
              minDelta = delta
            }
          }

          router = new mapObject.route([
            // Список точек, которые необходимо посетить - 1 точка
            "г. Москва МКАД " + minJ + " км",
            address
          ])
            .then(function (route) {

              const way = route.getPaths().get(0)
              const segments = way.getSegments()
              const numOfSegments = segments.length
              let currSegment = numOfSegments - 1
              let segment
              let Dist = 0

              while (currSegment >= 0) {

                segment = segments[currSegment]

                // если данный сегмент маршрута строится по МКАД, не учитываем
                if (segment.getStreet().indexOf("МКАД") >= 0)
                  break
                currSegment--
              }

              for (i = currSegment + 1; i < numOfSegments; i++) {
                Dist = Dist + segments[i].getLength()
              }

              cb(Dist)

            }, function (error) {
              console.log("Возникла ошибка: " + error.message)
            })


        } else {
          //точка изначально внутри МКАДа, маршрут не считаем
          console.log("Точка внутри МКАД")
          cb(0)
        }

      })
      .catch(function (error) {
        console.error("Возникла ошибка: " + error.message);
        cb(-1)
      })

  }
}

if (!window.YANDEX_MAP_KEY) {
  console.error("Не указан ключ Яндекс!")
} else {
  ymaps
    .load(`https://api-maps.yandex.ru/2.1/?lang=ru-RU&apikey=${window.YANDEX_MAP_KEY}`)
    .then(maps => {
      window.GEOCODER.mapObject = maps
      window.dispatchEvent(new Event("ymaps_ready"))
    })
    .catch(error => console.log('Failed to load Yandex Maps', error))
}

require("./components/calc")
require("./components/map-calc")
require("./components/contacts-map")
require("./components/map")

