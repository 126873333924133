import "./contacts-map.scss"

const {$} = window

$(".contacts-map--link-js").on("click", e => {
  e.preventDefault()
  $([document.documentElement, document.body]).animate({
    scrollTop: $("#contacts-map-container").offset().top
  }, 250)
})

const $map = document.getElementById("contacts-map-container")

if ($map) {

  const $markers = [...document.querySelectorAll("#contacts-map-container .marker")]

  const center = JSON.parse($map.dataset.center)
  const zoom = parseInt($map.dataset.zoom)

  const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)

  let markers = []
  let map

  const updateMarkers = id => {

    const {GEOCODER: {mapObject}} = window

    markers.forEach(marker => {
      map.geoObjects.remove(marker)
    })
    markers = []

    $markers && $markers.forEach($marker => {
      let icon = 'assets/images/logo-mobile@3x.png'
      const marker = new mapObject.Placemark(
        JSON.parse($marker.dataset.center),
        {
          hintContent: $marker.dataset.title,
          balloonContentHeader: $marker.dataset.title,
          balloonContentBody: $marker.dataset.body,
          balloonContentFooter: $marker.dataset.footer,
        }, {
          iconLayout: 'default#image',
          hideIconOnBalloonOpen: true,
          iconImageHref: icon,
          iconImageSize: [46, 94],
          iconImageOffset: [-23, -94]
        })
      marker.id = $marker.id
      markers.push(marker)
      map && map.geoObjects.add(marker)
    })
  }
  window.addEventListener("ymaps_ready", function (e) {

    const {GEOCODER: {mapObject}} = window

    map = new mapObject.Map('contacts-map-container', {
      center,
      zoom,
      controls: ['zoomControl']
    })
    if (!isMobile) map.behaviors.disable('scrollZoom')
    updateMarkers()
  })


  // ymaps
  //   .load("https://api-maps.yandex.ru/2.1/?lang=ru-RU")
  //   .then(m => {
  //     maps = m
  //     map = new maps.Map('contacts-map-container', {
  //       center,
  //       zoom,
  //       controls: ['zoomControl']
  //     })
  //     if (!isMobile) map.behaviors.disable('scrollZoom')
  //     updateMarkers()
  //
  //   })
  //   .catch(error => console.log('Failed to load Yandex Maps', error))

}